@import "../../styles/_common.css";



.email-container{

  padding: 0em 0em;
  background-color: #FAF6F6;
  background-image: url("https://api.coellalove.com/media/35.JPG") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  min-height: inherit !important;


}

.email-segment {
  background-color: #FFFFFF !important;
  padding:1em 1em !important;
  text-align: center !important;

}

.email-grid {

  padding:2em 0em !important;


}

@media only screen and (max-width: 768px) {
  .email-container{
    margin-top: 2em;
  }

}

@media screen and (min-width: 768px) {
  .email-segment {

    max-width: 500px;


  }

}
