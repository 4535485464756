@import "../../styles/_common.css";


.banner {
    position: fixed !important;
    justify-content: center;
    text-align: center;;
    top: 0; /* Position the navbar at the top of the page */
    width: 100%; /* Full width */
    height: 3em;
    display: flex;
    margin: 0px !important;
    padding: 0px !important;
    border-radius: 0px !important;
    background: #000000 !important;
    color:#ffffff !important;

    z-index: 1000 !important;

}

.banner-item {
    margin: auto !important;
    font-size: 14px !important;

    z-index: 100 !important;
}

.spring-item {

    margin: auto !important;

}
