@import url(https://fonts.googleapis.com/css2?family=Lato&family=Newsreader&family=Open+Sans&family=Playfair+Display&family=Raleway&family=Roboto&family=Permanent+Marker&family=Source+Code+Pro&display=swap);
/*COLORS*/
$white: #ffffff;
$black: #000000;
$lightGray:#D5D4D2;
$medGray:#989796;
$slateGray:#5B5B5A;
$cream:#F3F2F0;
$taupePink:#DABBBB;
$beige:#D9C4C1;
$beige2:#BEA69C;
$gold: #D9C890;

$boxShadow: 0 2px 20px 0 rgba(185, 188, 210, 0.2);

/*FONTS*/

/* Font Variables*/
$sourceCode: 'Source Code Pro', monospace;
$raleway: 'Raleway', sans-serif;
$roboto: 'Roboto', sans-serif;
$lato: 'Lato', sans-serif;
$open-sans: 'Open Sans', sans-serif;
$playfair-display: 'Playfair Display', sans-serif;
$newsreader: 'Newsreader', sans-serif;

.ui.dimmer {

  top: 110px !important;
}

p {
  font-family: $lato;
}

a:hover {
 cursor:pointer;
 font-weight: 600;
}

.ui.menu .active.item {
    font-weight: 700 !important;
    color: #000000 !important;

    text-decoration: underline;
    -webkit-text-decoration-color: #D5D4D2;
            text-decoration-color: #D5D4D2;
    text-decoration-thickness: 4px;
    text-underline-offset: 3px;
    margin: 1em 0em;
}

a {
 color:#000000 !important;
}

.btnCoella {
  display: inline-block !important;
  border: 1px solid #000 !important;
  bordercolor: #000000 !important;
  border-radius: 0 !important;
  padding: 10px 15px;
  font-family: $lato;
  color: $black !important;
  background-color: $white !important;
  transition: .2s all ease;
  text-align: center;
}

.btnCoella:hover {
  color: #fff !important;
  text-decoration: none !important;
  background: #000 !important;
}

.section-container {
    /*margin: 120px 0px!important;*/
    height:100vh !important;
}

.section-header {
  color: $slateGray !important;
  font-family: $newsreader !important;
}

.order-segment {
  background-color: #FFFFFF !important;
  padding:1em 1em !important;


}

.order-segment-header {
  margin-bottom: 1em;
  font-size: 14px !important;
  font-weight: 600 !important;


}
/*
.ui.card {
    min-width: 100px !important;

    margin: 10px;
    padding: 10px;
}
*/

/*Polish up Smeantic segment formatting
.ui.segment {
  border-radius: 2px;
	background-color: #ffffff;
	box-shadow: 0 2px 20px 0 rgba(185, 188, 210, 0.2);
	border: solid 1px #e8e8ec;
}

.ui.basic.segment {
    background-color: #f8f8f8 !important;
    border: none !important;
    border-top: 1px solid #e8e8ec !important;
    padding-right: 1.5rem;
}
*/




@media only screen and (max-width: 768px) {
  .card-header {
      font-size: 10px !important;
  }

}

@media screen and (min-width: 768px) {
  .order-segment-header {

    font-size: 16px !important;


  }

}

.login-button{
  border-radius: 0 !important;
}

.login-segment {

}

.email-container{

  padding: 0em 0em;
  background-color: #FAF6F6;
  background-image: url("https://api.coellalove.com/media/35.JPG") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  min-height: inherit !important;


}

.email-segment {
  background-color: #FFFFFF !important;
  padding:1em 1em !important;
  text-align: center !important;

}

.email-grid {

  padding:2em 0em !important;


}

@media only screen and (max-width: 768px) {
  .email-container{
    margin-top: 2em;
  }

}

@media screen and (min-width: 768px) {
  .email-segment {

    max-width: 500px;


  }

}

/*FRONT PAGE*/
.mobile-top-jumbotron {
  border-radius: 0 !important;
  border:none !important;
  box-shadow: none !important;
  background-color: #000000!important;
  height:500px !important;
  background-image: url("https://api.coellalove.com/media/frontpage/active/mobile_top.jpeg") !important;
  background-size: cover !important;

}

.mobile-stella-jumbotron {
  border-radius: 0 !important;
  border:none !important;
  box-shadow: none !important;
  background-color: #000000!important;
  height:350px !important;
  background-image: url("https://api.coellalove.com/media/stella_antoinette.png") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.mobile-stella-jumbotron h1{
  color:#FFFFFF !important;
  font-size:24px !important;
  font-weight:400 !important;
  font-family:"Playfair Display"!important;
  margin:0 !important;

}

.header-top-jumbotron-right {
  color:#FFFFFF !important;
  font-size:50px !important;

  font-family:"Playfair Display"!important;
  margin:0 !important;
}



.desktop-stella-jumbotron-left h1{
  color:#FFFFFF !important;
  font-size:30px !important;
  font-weight:400 !important;
  font-family:"Playfair Display"!important;
  margin:0 !important;
}

.desktop-stella-jumbotron-left h1{
  color:#FFFFFF !important;
  font-size:30px !important;
  font-weight:400 !important;
  font-family:"Playfair Display"!important;
  margin:0 !important;
}

.stella-logo {
  text-align: center;
  width:180px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.stella-logo-desktop {
  text-align: center;
  width:250px;

}

.sale-banner-text {
  margin: 0em 0em 0.3em 0em !important;
  font-family:"Playfair Display"!important;
  color:#FFFFFF !important;
  font-size: 28px !important;
  font-weight: 500 !important;
}

.sale-banner-text-sub {
  margin: 0.3em 0em 0em 0em!important;
  font-family:"Lato"!important;
  font-size:18px !important;
  color:#5B5B5A !important;

}

.sale-banner-text-box {
  text-align:center;
  padding: 1em 2em !important;
  margin: 0;
  width:100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.desktop-sale-banner {

  padding: 1em 1em !important;
  border-radius: 0 !important;
  border:none !important;
  box-shadow: none !important;
  background-color: #DABBBB!important;
  min-height:200px !important;
  width:100%;


}

.desktop-top-jumbotron-left {
  border-radius: 0 !important;
  border:none !important;
  box-shadow: none !important;
  background-color: #000000!important;
  height:500px !important;

}

.desktop-top-jumbotron-right {
  border-radius: 0 !important;
  border:none !important;
  box-shadow: none !important;
  background-color: #000000!important;
  height:500px !important;
  background-image: url("https://api.coellalove.com/media/frontpage/active/desktop_top_right.jpeg") !important;
  background-size: cover !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.desktop-top-jumbotron-right h1{
  color:#FAF3E6 !important;
  font-size:80px !important;
  font-family:"Playfair Display"!important;
  margin:0 !important;
}

.desktop-stella-jumbotron-right {
  border-radius: 0 !important;
  border:none !important;
  box-shadow: none !important;
  background-color: #000000!important;
  height:500px !important;
  background-image: url("https://api.coellalove.com/media/bestseller_fall.png") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.desktop-stella-jumbotron-left {
  border-radius: 0 !important;
  border:none !important;
  box-shadow: none !important;
  background-color: #000000!important;
  height:500px !important;
  background-image: url("https://api.coellalove.com/media/stella_antoinette.png") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.mobile-bottom-jumbotron {
  height:700px !important;
  border-radius: 0 !important;
  border:none !important;
  box-shadow: none !important;
  background-image: url("https://api.coellalove.com/media/bestseller_fall.png") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;

}

.mobile-bottom-jumbotron h1{
  color:#FFFFFF !important;
  font-size:50px !important;
  font-family:"Playfair Display"!important;
  margin:0 !important;
}

.desktop-stella-jumbotron-right h1{
  color:#FFFFFF !important;
  font-size:50px !important;
  font-family:"Playfair Display"!important;
  margin:0 !important;
}

.header-animation-center{
  margin: 0;
  position: absolute;
  min-width: 80%;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.category-font{
  font-family: "Playfair Display" !important;
  font-size: 26px !important;
  color: #FFFFFF !important;
  margin: 0em !important;
}

.featured-font{
  font-family: "Playfair Display" !important;
  font-size: 18px !important;
  color: #000000 !important;
  margin: 0em !important;
}

.category-segment{
  background-image: url("https://api.coellalove.com/media/image.png") !important;
  border-radius: 0 !important;
  height:200px !important;
  border:none !important;
}

.category-segment-earrings{
  background-image: url("https://api.coellalove.com/media/earring_fall.png") !important;
  border-radius: 0 !important;
  height:200px !important;
  border:none !important;
  background-size: cover !important;
}

.category-segment-necklaces{
  background-image: url("https://api.coellalove.com/media/frontpage/2023_summer_necklace.jpg") !important;
  border-radius: 0 !important;
  height:400px !important;
  border:none !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.category-segment-bracelets{
  background-image: url("https://api.coellalove.com/media/front_page_bracelet_2.jpeg") !important;
  border-radius: 0 !important;
  height:200px !important;
  border:none !important;
    background-size: cover !important;
}

.category-segment-anklets{
  background-image: url("https://api.coellalove.com/media/frontpage/2023_summer_anklet.png") !important;
  border-radius: 0 !important;
  height:400px !important;
  border:none !important;
  background-size: cover !important;
}

.shop-now{
  font-size: 14px !important;
  color: #FFFFFF !important;
  margin:0;
  text-decoration: underline;
}

.shop-now:hover{
  text-decoration: underline;

}

.stella-sub{
  font-size: 12px !important;
  color: #FFFFFF !important;
  margin:0;


}


.top-jumbotron{
  /*background-image: url('../../static/img/palm_earring_banner_mobile2.jpg') !important;
  background-repeat: no-repeat !important;
  background-size: auto !important;
    background-position: center !important;*/
  overflow: hidden;
  height: 500px;
  width:100%;
  margin: 0em !important;
  padding: 0em !important;
  border: none !important;
  text-align: center;
}

.jumbotron-left{
  display: inline-block;
  vertical-align: middle;

  background-color: #5B5B5A !important;
  height: 100%;
  float:left;
  width: 40% !important;
}

.jumbotron-right{
  display: inline-block;
  vertical-align: middle;
  background-color: #5B5B5A !important;
  color: $slateGray !important;
  height: 100% !important;
  width: 100% !important;
  float:right;
}

.best-sellers{
  border: none !important;
  margin: 1em 0em !important;
  padding: 2em !important;
  height: 100%;
}

.top-header{
  font-size: 4em !important;
  color: #FFFFFF !important;
}




@media screen and (min-width: 768px) {
  .mobile-bottom-jumbotron {
    background-image: url("https://api.coellalove.com/media/frontpage/2023_summer_anklet.png") !important;
    height:500px !important;
  }
  .mobile-stella-jumbotron h1{

    font-size:40px !important;

  }
  .category-segment-bracelets{
    height:400px !important;
  }
  .category-segment-earrings{
    height:400px !important;
  }
  .top-jumbotron {
    /*background-image: url('../../static/img/palm_earring_banner4.jpg') !important;
    */
  }
  .header-animation-center{
    left: 70%;
  }
  .stella-sub{
    font-size: 14px !important;

  }
  .sale-banner-text {
    font-size: 32px !important;

  }
  .sale-banner-text-sub {
    font-size: 20px !important;

  }
  .desktop-sale-banner {

    min-height:150px !important;


  }
}

.main {
  min-width: 100px;
   padding: 0 0px;
   margin: 0 auto;
   height: 260px;
}

.transitionsItem {
  overflow: hidden;
  width: 100%;
  color: white;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
      justify-content: flex-start;
  -ms-flex-align: center;
      align-items: center;
  font-family: "Playfair Display" !important;
  font-size: 80px;
  font-weight: 900;
  position: relative;
  will-change: transform, opacity, height;
  white-space: nowrap;
  cursor: pointer;
  line-height: 80px;

}

.header-container {
  display: block;

  height: 100%;


}

.carousel {
  margin: 1em 0em;

}

.carousel-container{
  position:relative;
}
.button-back{

  border-style:none;
    background-color:transparent;
    color:#000000;
  position: absolute !important;
  top: 50% !important;
  left: -1% !important;
}

.button-next{

  border-style:none;
  background-color:transparent;
  color:#000000 !important;
  position: absolute;
  top: 50% !important;
  right: -1% !important;

}

.carousel-card-header {
  font-family: "Roboto" !important;
  font-size:14px !important;
}

.carousel-card-price {
  font-family: "Roboto" !important;
  font-size:14px !important;
  margin: 0em 1em 0em 0em;
}

.carousel-card-content {
  min-height: 50px !important;
  box-shadow: none !important;
  border-style: none !important;

}

.carousel-dot{
  background-color: #FFFFFF !important;
}







@media screen and (min-width: 768px) {
  .carousel-card-content {
    min-height: 100px !important;
  }
  .carousel-card-header {

    font-size:12px !important;

  }
  .carousel-card-price {

    font-size:12px !important;

  }
}

.product-search {
  padding: 0em 0em 0em 0em;
  margin: 0em 0em 0em 0em;

}

.product-header {
  font-family:"Playfair Display" !important;

}

.product-grid {
  height: 100% !important;

  z-index: 0;
  position: relative;
  min-height: 400px !important;
}

.filter-dropdown {
  background-color: #FFFFFF !important;
  border-style: thin !important;
  border-color: #000000 !important;
}

.container-grid {
  margin:0em !important;
  padding:0em 0.5em;
}

.muuri-item {
  z-index: 0;
  min-height: 280px;
  cursor: pointer;
  position: absolute;
  width: 50%;
  will-change: transform;
  -ms-touch-action: pan-y;
      touch-action: pan-y;
}

.muuri-item-content {
  margin: 0.5em;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  position: relative;
  -ms-touch-action: pan-y;
      touch-action: pan-y;
}



@media screen and (min-width: 768px) {
  .muuri-item {
    width: 33%;
    min-height: 415px;
  }

}

.product-card {
  box-shadow: none !important;
  border-style: none !important;

}
.product-card-header {
  font-family: "Roboto" !important;
  font-size:14px !important;

}

.product-card-price {
  font-family: "Roboto" !important;
  font-size:16px !important;
  margin: 0em 0.5em 0em 0em;
}

.product-card-price-slash {
  font-family: "Roboto" !important;
  font-size:14px !important;
  color: #D5D4D2;
  margin: 0em 0.5em 0em 0em;
  text-decoration: line-through;
}

.product-card-button {
  background-color: #DABBBB !important;;
  color: #FFFFFF !important;
  font-weight:600 !important;
  border-radius: 0 !important;
}

.product-card-content {
  min-height: 90px !important;
  box-shadow: none !important;
  border-style: none !important;
  padding: 1em 0em !important;
}

@media screen and (min-width: 768px) {

  .product-card-content {
    min-height: 110px !important;
  }
  .product-card-header {

    font-size:16px !important;

  }
}

.product-header {
  font-family:"Roboto" !important;
  font-size:26px ;
}

.variation-box{
  border-style: solid;
  border-width: 1px;
  border-radius: 0px !important;
  min-height: 40px !important;
  margin: 0.5em  1em  0.5em  00em !important;
}

.button-box {
  min-height: 1em;
  display: block!important;
  text-align: center;
  padding:0;
  position:relative;
  height:100%!important;
  width:100%!important;
  border-style: solid;
  border-color: #D5D4D2;
  border-radius: 0em;
  border-width: 1px;
}

.button-box-text{
  width:100%!important;
  position:absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.summary-trailsText {
  position: relative;
  width: 100%;
  height: 80px;
  line-height: 80px;
  color: black;
  font-size: 6em;
  font-weight: 800;
  letter-spacing: -0.05em;
  will-change: transform, opacity;
  overflow: hidden;
}

.checkout-button{
  border-radius: 0 !important;
}
.summary-trailsText > div {
  padding-right: 0.05em;
  overflow: hidden;
}

.item-segment {
  border-radius: 10px !important;
  margin:1em 0em 1em 0em!important;
  position: relative!important;
  padding:1em 0em !important;
  width:100% !important;
}

.trail-item {

  margin:1em 0em;
}

.order-review {



}

@media screen and (max-width: 768px) {
  .order-review {

    box-shadow: none;
    border-bottom: 1px solid #F1F1F1;

  }
}

.coupon-segment {
  background-color: #FFFFFF !important;
  padding:1em 1em !important;


}


@media only screen and (max-width: 768px) {
  .coupon-segment {
      padding:1em 0em !important;
      margin:0em 0em !important;
  }

}

.coupon-segment {
  background-color: #FFFFFF !important;
  padding:1em 1em !important;


}


@media only screen and (max-width: 768px) {
  .coupon-segment {
      padding:1em 0em !important;
      margin:0em 0em !important;
  }

}

.order-review {



}

@media screen and (max-width: 768px) {
  .order-review {

    box-shadow: none;
    border-bottom: 1px solid #F1F1F1;

  }
}

.StripeElement {

  padding: 10px 12px;

  color: #32325d;
  background-color: $white;
  border: 1px solid transparent;
  border-color: $lightGray;
  border-radius: 4px;

  font-family: $sourceCode;
  font-size: 16px;
  font-weight: 500;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  transition: box-shadow 150ms ease;

}


.StripeElement .cardNumber {
  font-family: $sourceCode;
  font-size: 16px;
  font-weight: 500;
  display: -ms-flexbox;
  display: flex;
  margin: 0 5px 10px;
}

.StripeElement .input {
  font-family: $sourceCode;
  font-size: 16px;
  font-weight: 500;
  display: -ms-flexbox;
  display: flex;
  margin: 0 5px 10px;
}

.StripeElement .field {
  position: relative;
  width: 100%;
  height: 4em;
  margin: 0 10px;
}

.StripeElement--invalid {
  border: 1px solid red;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.payment-mark {
  border-style:line;
  background-color: #000000 !important;
}

.checkout-status-box {
  box-shadow: none !important;
  border: none !important;

  margin:0em 0em 2em 0em !important;

}

.cart-empty-header{
  font-weight: 300 !important;

}

.order-container{
  top: 0px;
  padding: 0em 0em;
  background-color: #FAF6F6;
  /*background-image: url("https://api.coellalove.com/media/30.JPG") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;*/
  min-height: 600px !important;
}


@media screen and (min-width: 768px) {
  .order-container {
    padding: 3em 0em;
    min-height: 800px !important;
  }

}

.main-container {
    margin: 9em 0em 0em 0em !important;
    min-height: 600px !important;
    width:100% !important;
    padding:0em !important;
    overflow: hidden !important;

}

.dimmer-container {
  max-height: 100vh !important;
}

@media screen and (min-width: 768px) {
  .main-container {
    margin: 13em 0em 0em 0em !important;
    min-height: 800px !important;
  }

}

.parking-container {
  background-color: #000000;
  position: relative;
  height: 100vh ;
  padding:0;
}

.parking-center{
  margin: 0;
  position: absolute;
  min-width: 80%;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.parking-grid {
  margin:0 !important;
  height:100%
}

.parking-input {


  display:block;
}

.subscribe-header {
  font-size: 18px;
  color:#FFFFFF !important;
  font-family: "Open Sans" !important;
}

.about-text{
  font-size:16px;
  
  color:#000000;
}

.App {
    -webkit-font-smoothing: antialiased;
}

.banner {
    position: fixed !important;
    -ms-flex-pack: center;
        justify-content: center;
    text-align: center;;
    top: 0; /* Position the navbar at the top of the page */
    width: 100%; /* Full width */
    height: 3em;
    display: -ms-flexbox;
    display: flex;
    margin: 0px !important;
    padding: 0px !important;
    border-radius: 0px !important;
    background: #000000 !important;
    color:#ffffff !important;

    z-index: 1000 !important;

}

.banner-item {
    margin: auto !important;
    font-size: 14px !important;

    z-index: 100 !important;
}

.spring-item {

    margin: auto !important;

}


.left-siderbar-menu{
  font-weight:300;
  font-size: 18px;
  color:#000000;
}

.left-siderbar-button{
  background-color: #DABBBB !important;
  color: #FFFFFF !important;
  font-weight:600 !important;
  border-radius: 0 !important;

}

#nav-top {
  /*padding: 0 0 4rem 0;*/
  background: #000000;
  height: 40px;
  color: #ffffff;

}
.nav-menu-right {
  margin-left: auto;
  margin-right: 0;
}

.nav-menu-right:hover {
    cursor: pointer;
}

.nav-menu-left {
  margin-left: 0;
  margin-right: auto;
}

.nav-link {
  font-size: 12px;
  font-weight: 600;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 3px;
  -webkit-text-decoration-color: #5B5B5A;
          text-decoration-color: #5B5B5A;
}
.top-menu {
  height:6em !important;
  border-radius: 0px !important;
  border-width: thin !important;
  box-shadow: none !important;
  padding: 0em 4em !important;
  z-index: 1 !important;
  background-color: #FFFFFF !important;
  top:3em !important;

}

.menu-grid{

  width:100% !important;
}

.cart-number-container {

  margin: auto;

  display:-ms-flexbox;

  display:flex;
  -ms-flex-align:center;
      align-items:center;
}

.cart-number {
  margin: 0em 0.5em;
  color:#000000;
  float:left;
  font-size: 14px;
  font-family: "Roboto";

}

.cart-number-icon {

  margin:0em !important;


}

.sidebar-item{
  padding:0em !important;
  margin: auto;

  display:-ms-flexbox;

  display:flex;
  -ms-flex-align:center;
      align-items:center;
}

.sidebar-item-desc{
  height:100%;
  width:100%;
  vertical-align: top;
  text-align: left;
  margin: 0em 1em;
}


.product-menu {
  height:4em !important;
  border-radius: 0px;
  border-style: none !important;
  border-bottom: 1px solid #D5D4D2 !important;
  top:0px !important;
  width: 100%;
  z-index: 1 !important;
  background-color: #FFFFFF !important;
  margin-top:9em !important;
  position: fixed!important;
}

.product-nav:hover{
  font-weight: 700 !important;
  background-color: #FFFFFF !important;

}
.product-nav-container{
  width: 50% !important;
}

@media only screen and (max-width: 767px) {
.ui.menu>.ui.container {
    width: 100%!important;
    margin-left: 1em!important;
    margin-right: 1em!important;
}
}

.sidebar {
z-index: 1005 !important;
  padding: 1em;
}

.sidebar-btn {

  padding: 1em;
}

.sidebar-exit {
  text-align: left;
  }


.MediaTest {

    display: contents;

}

.shopping-bag-button{
  border-radius: 0 !important;
}

/*FOOTER*/
.footer {
  /*padding: 0 0 4rem 0;*/
  position: fixed !important;

  padding: 80px 0;
  height: 400px;
  color: #000000;
  font-size: 14px;
  left: 0;
  bottom: 0 !important;
  width: 100%,
}

.footer-segment {
  margin: 0em 0em 0em !important;
  padding: 5em 0em !important;
  background-color: #000000 !important;
}
.support-link{
  color:#FFFFFF!important;

}

.footer-list{
  color:#FFFFFF !important;
}

html{
  height:100%;

  margin: 0;
  padding: 0;
  overflow: auto;

}

body { height: auto; }


#root {
  height:auto;
  margin: 0;
  padding: 0;


}


/*Activate to debug elements
*{ border: 1px solid red; }
*/

