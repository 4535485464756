@import "./_variables.css";







.ui.dimmer {

  top: 110px !important;
}

p {
  font-family: $lato;
}

a:hover {
 cursor:pointer;
 font-weight: 600;
}

.ui.menu .active.item {
    font-weight: 700 !important;
    color: #000000 !important;

    text-decoration: underline;
    text-decoration-color: #D5D4D2;
    text-decoration-thickness: 4px;
    text-underline-offset: 3px;
    margin: 1em 0em;
}

a {
 color:#000000 !important;
}

.btnCoella {
  display: inline-block !important;
  border: 1px solid #000 !important;
  bordercolor: #000000 !important;
  border-radius: 0 !important;
  padding: 10px 15px;
  font-family: $lato;
  color: $black !important;
  background-color: $white !important;
  transition: .2s all ease;
  text-align: center;
}

.btnCoella:hover {
  color: #fff !important;
  text-decoration: none !important;
  background: #000 !important;
}

.section-container {
    /*margin: 120px 0px!important;*/
    height:100vh !important;
}

.section-header {
  color: $slateGray !important;
  font-family: $newsreader !important;
}

.order-segment {
  background-color: #FFFFFF !important;
  padding:1em 1em !important;


}

.order-segment-header {
  margin-bottom: 1em;
  font-size: 14px !important;
  font-weight: 600 !important;


}
/*
.ui.card {
    min-width: 100px !important;

    margin: 10px;
    padding: 10px;
}
*/

/*Polish up Smeantic segment formatting
.ui.segment {
  border-radius: 2px;
	background-color: #ffffff;
	box-shadow: 0 2px 20px 0 rgba(185, 188, 210, 0.2);
	border: solid 1px #e8e8ec;
}

.ui.basic.segment {
    background-color: #f8f8f8 !important;
    border: none !important;
    border-top: 1px solid #e8e8ec !important;
    padding-right: 1.5rem;
}
*/




@media only screen and (max-width: 768px) {
  .card-header {
      font-size: 10px !important;
  }

}

@media screen and (min-width: 768px) {
  .order-segment-header {

    font-size: 16px !important;


  }

}
