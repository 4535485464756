@import "../../styles/_common.css";

/*FRONT PAGE*/
.mobile-top-jumbotron {
  border-radius: 0 !important;
  border:none !important;
  box-shadow: none !important;
  background-color: #000000!important;
  height:500px !important;
  background-image: url("https://api.coellalove.com/media/frontpage/active/mobile_top.jpeg") !important;
  background-size: cover !important;

}

.mobile-stella-jumbotron {
  border-radius: 0 !important;
  border:none !important;
  box-shadow: none !important;
  background-color: #000000!important;
  height:350px !important;
  background-image: url("https://api.coellalove.com/media/stella_antoinette.png") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.mobile-stella-jumbotron h1{
  color:#FFFFFF !important;
  font-size:24px !important;
  font-weight:400 !important;
  font-family:"Playfair Display"!important;
  margin:0 !important;

}

.header-top-jumbotron-right {
  color:#FFFFFF !important;
  font-size:50px !important;

  font-family:"Playfair Display"!important;
  margin:0 !important;
}



.desktop-stella-jumbotron-left h1{
  color:#FFFFFF !important;
  font-size:30px !important;
  font-weight:400 !important;
  font-family:"Playfair Display"!important;
  margin:0 !important;
}

.desktop-stella-jumbotron-left h1{
  color:#FFFFFF !important;
  font-size:30px !important;
  font-weight:400 !important;
  font-family:"Playfair Display"!important;
  margin:0 !important;
}

.stella-logo {
  text-align: center;
  width:180px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.stella-logo-desktop {
  text-align: center;
  width:250px;

}

.sale-banner-text {
  margin: 0em 0em 0.3em 0em !important;
  font-family:"Playfair Display"!important;
  color:#FFFFFF !important;
  font-size: 28px !important;
  font-weight: 500 !important;
}

.sale-banner-text-sub {
  margin: 0.3em 0em 0em 0em!important;
  font-family:"Lato"!important;
  font-size:18px !important;
  color:#5B5B5A !important;

}

.sale-banner-text-box {
  text-align:center;
  padding: 1em 2em !important;
  margin: 0;
  width:100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.desktop-sale-banner {

  padding: 1em 1em !important;
  border-radius: 0 !important;
  border:none !important;
  box-shadow: none !important;
  background-color: #DABBBB!important;
  min-height:200px !important;
  width:100%;


}

.desktop-top-jumbotron-left {
  border-radius: 0 !important;
  border:none !important;
  box-shadow: none !important;
  background-color: #000000!important;
  height:500px !important;

}

.desktop-top-jumbotron-right {
  border-radius: 0 !important;
  border:none !important;
  box-shadow: none !important;
  background-color: #000000!important;
  height:500px !important;
  background-image: url("https://api.coellalove.com/media/frontpage/active/desktop_top_right.jpeg") !important;
  background-size: cover !important;
  display: flex;
  align-items: center;
}

.desktop-top-jumbotron-right h1{
  color:#FAF3E6 !important;
  font-size:80px !important;
  font-family:"Playfair Display"!important;
  margin:0 !important;
}

.desktop-stella-jumbotron-right {
  border-radius: 0 !important;
  border:none !important;
  box-shadow: none !important;
  background-color: #000000!important;
  height:500px !important;
  background-image: url("https://api.coellalove.com/media/bestseller_fall.png") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.desktop-stella-jumbotron-left {
  border-radius: 0 !important;
  border:none !important;
  box-shadow: none !important;
  background-color: #000000!important;
  height:500px !important;
  background-image: url("https://api.coellalove.com/media/stella_antoinette.png") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.mobile-bottom-jumbotron {
  height:700px !important;
  border-radius: 0 !important;
  border:none !important;
  box-shadow: none !important;
  background-image: url("https://api.coellalove.com/media/bestseller_fall.png") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;

}

.mobile-bottom-jumbotron h1{
  color:#FFFFFF !important;
  font-size:50px !important;
  font-family:"Playfair Display"!important;
  margin:0 !important;
}

.desktop-stella-jumbotron-right h1{
  color:#FFFFFF !important;
  font-size:50px !important;
  font-family:"Playfair Display"!important;
  margin:0 !important;
}

.header-animation-center{
  margin: 0;
  position: absolute;
  min-width: 80%;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.category-font{
  font-family: "Playfair Display" !important;
  font-size: 26px !important;
  color: #FFFFFF !important;
  margin: 0em !important;
}

.featured-font{
  font-family: "Playfair Display" !important;
  font-size: 18px !important;
  color: #000000 !important;
  margin: 0em !important;
}

.category-segment{
  background-image: url("https://api.coellalove.com/media/image.png") !important;
  border-radius: 0 !important;
  height:200px !important;
  border:none !important;
}

.category-segment-earrings{
  background-image: url("https://api.coellalove.com/media/earring_fall.png") !important;
  border-radius: 0 !important;
  height:200px !important;
  border:none !important;
  background-size: cover !important;
}

.category-segment-necklaces{
  background-image: url("https://api.coellalove.com/media/frontpage/2023_summer_necklace.jpg") !important;
  border-radius: 0 !important;
  height:400px !important;
  border:none !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.category-segment-bracelets{
  background-image: url("https://api.coellalove.com/media/front_page_bracelet_2.jpeg") !important;
  border-radius: 0 !important;
  height:200px !important;
  border:none !important;
    background-size: cover !important;
}

.category-segment-anklets{
  background-image: url("https://api.coellalove.com/media/frontpage/2023_summer_anklet.png") !important;
  border-radius: 0 !important;
  height:400px !important;
  border:none !important;
  background-size: cover !important;
}

.shop-now{
  font-size: 14px !important;
  color: #FFFFFF !important;
  margin:0;
  text-decoration: underline;
}

.shop-now:hover{
  text-decoration: underline;

}

.stella-sub{
  font-size: 12px !important;
  color: #FFFFFF !important;
  margin:0;


}


.top-jumbotron{
  /*background-image: url('../../static/img/palm_earring_banner_mobile2.jpg') !important;
  background-repeat: no-repeat !important;
  background-size: auto !important;
    background-position: center !important;*/
  overflow: hidden;
  height: 500px;
  width:100%;
  margin: 0em !important;
  padding: 0em !important;
  border: none !important;
  text-align: center;
}

.jumbotron-left{
  display: inline-block;
  vertical-align: middle;

  background-color: #5B5B5A !important;
  height: 100%;
  float:left;
  width: 40% !important;
}

.jumbotron-right{
  display: inline-block;
  vertical-align: middle;
  background-color: #5B5B5A !important;
  color: $slateGray !important;
  height: 100% !important;
  width: 100% !important;
  float:right;
}

.best-sellers{
  border: none !important;
  margin: 1em 0em !important;
  padding: 2em !important;
  height: 100%;
}

.top-header{
  font-size: 4em !important;
  color: #FFFFFF !important;
}




@media screen and (min-width: 768px) {
  .mobile-bottom-jumbotron {
    background-image: url("https://api.coellalove.com/media/frontpage/2023_summer_anklet.png") !important;
    height:500px !important;
  }
  .mobile-stella-jumbotron h1{

    font-size:40px !important;

  }
  .category-segment-bracelets{
    height:400px !important;
  }
  .category-segment-earrings{
    height:400px !important;
  }
  .top-jumbotron {
    /*background-image: url('../../static/img/palm_earring_banner4.jpg') !important;
    */
  }
  .header-animation-center{
    left: 70%;
  }
  .stella-sub{
    font-size: 14px !important;

  }
  .sale-banner-text {
    font-size: 32px !important;

  }
  .sale-banner-text-sub {
    font-size: 20px !important;

  }
  .desktop-sale-banner {

    min-height:150px !important;


  }
}
