@import "../../styles/_common.css";


.product-search {
  padding: 0em 0em 0em 0em;
  margin: 0em 0em 0em 0em;

}

.product-header {
  font-family:"Playfair Display" !important;

}

.product-grid {
  height: 100% !important;

  z-index: 0;
  position: relative;
  min-height: 400px !important;
}

.filter-dropdown {
  background-color: #FFFFFF !important;
  border-style: thin !important;
  border-color: #000000 !important;
}

.container-grid {
  margin:0em !important;
  padding:0em 0.5em;
}
