@import "../../styles/_common.css";

.product-header {
  font-family:"Roboto" !important;
  font-size:26px ;
}

.variation-box{
  border-style: solid;
  border-width: 1px;
  border-radius: 0px !important;
  min-height: 40px !important;
  margin: 0.5em  1em  0.5em  00em !important;
}
