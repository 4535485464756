
.left-siderbar-menu{
  font-weight:300;
  font-size: 18px;
  color:#000000;
}

.left-siderbar-button{
  background-color: #DABBBB !important;
  color: #FFFFFF !important;
  font-weight:600 !important;
  border-radius: 0 !important;

}
