@import "../../styles/_common.css";

.main-container {
    margin: 9em 0em 0em 0em !important;
    min-height: 600px !important;
    width:100% !important;
    padding:0em !important;
    overflow: hidden !important;

}

.dimmer-container {
  max-height: 100vh !important;
}

@media screen and (min-width: 768px) {
  .main-container {
    margin: 13em 0em 0em 0em !important;
    min-height: 800px !important;
  }

}
