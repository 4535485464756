/*COLORS*/
$white: #ffffff;
$black: #000000;
$lightGray:#D5D4D2;
$medGray:#989796;
$slateGray:#5B5B5A;
$cream:#F3F2F0;
$taupePink:#DABBBB;
$beige:#D9C4C1;
$beige2:#BEA69C;
$gold: #D9C890;

$boxShadow: 0 2px 20px 0 rgba(185, 188, 210, 0.2);

/*FONTS*/
@import url('https://fonts.googleapis.com/css2?family=Lato&family=Newsreader&family=Open+Sans&family=Playfair+Display&family=Raleway&family=Roboto&family=Permanent+Marker&family=Source+Code+Pro&display=swap');

/* Font Variables*/
$sourceCode: 'Source Code Pro', monospace;
$raleway: 'Raleway', sans-serif;
$roboto: 'Roboto', sans-serif;
$lato: 'Lato', sans-serif;
$open-sans: 'Open Sans', sans-serif;
$playfair-display: 'Playfair Display', sans-serif;
$newsreader: 'Newsreader', sans-serif;
