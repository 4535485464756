@import "../../styles/_common.css";



.login-button{
  border-radius: 0 !important;
}

.login-segment {

}
