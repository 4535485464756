@import "../../styles/_common.css";

.StripeElement {

  padding: 10px 12px;

  color: #32325d;
  background-color: $white;
  border: 1px solid transparent;
  border-color: $lightGray;
  border-radius: 4px;

  font-family: $sourceCode;
  font-size: 16px;
  font-weight: 500;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;

}


.StripeElement .cardNumber {
  font-family: $sourceCode;
  font-size: 16px;
  font-weight: 500;
  display: -ms-flexbox;
  display: flex;
  margin: 0 5px 10px;
}

.StripeElement .input {
  font-family: $sourceCode;
  font-size: 16px;
  font-weight: 500;
  display: -ms-flexbox;
  display: flex;
  margin: 0 5px 10px;
}

.StripeElement .field {
  position: relative;
  width: 100%;
  height: 4em;
  margin: 0 10px;
}

.StripeElement--invalid {
  border: 1px solid red;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
