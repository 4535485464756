@import "../../styles/_common.css";

#nav-top {
  /*padding: 0 0 4rem 0;*/
  background: #000000;
  height: 40px;
  color: #ffffff;

}
.nav-menu-right {
  margin-left: auto;
  margin-right: 0;
}

.nav-menu-right:hover {
    cursor: pointer;
}

.nav-menu-left {
  margin-left: 0;
  margin-right: auto;
}

.nav-link {
  font-size: 12px;
  font-weight: 600;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 3px;
  text-decoration-color: #5B5B5A;
}
.top-menu {
  height:6em !important;
  border-radius: 0px !important;
  border-width: thin !important;
  box-shadow: none !important;
  padding: 0em 4em !important;
  z-index: 1 !important;
  background-color: #FFFFFF !important;
  top:3em !important;

}

.menu-grid{

  width:100% !important;
}

.cart-number-container {

  margin: auto;

  display:flex;
  align-items:center;
}

.cart-number {
  margin: 0em 0.5em;
  color:#000000;
  float:left;
  font-size: 14px;
  font-family: "Roboto";

}

.cart-number-icon {

  margin:0em !important;


}

.sidebar-item{
  padding:0em !important;
  margin: auto;

  display:flex;
  align-items:center;
}

.sidebar-item-desc{
  height:100%;
  width:100%;
  vertical-align: top;
  text-align: left;
  margin: 0em 1em;
}


.product-menu {
  height:4em !important;
  border-radius: 0px;
  border-style: none !important;
  border-bottom: 1px solid #D5D4D2 !important;
  top:0px !important;
  width: 100%;
  z-index: 1 !important;
  background-color: #FFFFFF !important;
  margin-top:9em !important;
  position: fixed!important;
}

.product-nav:hover{
  font-weight: 700 !important;
  background-color: #FFFFFF !important;

}
.product-nav-container{
  width: 50% !important;
}

@media only screen and (max-width: 767px) {
.ui.menu>.ui.container {
    width: 100%!important;
    margin-left: 1em!important;
    margin-right: 1em!important;
}
}

.sidebar {
z-index: 1005 !important;
  padding: 1em;
}

.sidebar-btn {

  padding: 1em;
}

.sidebar-exit {
  text-align: left;
  }


.MediaTest {

    display: contents;

}

.shopping-bag-button{
  border-radius: 0 !important;
}
