@import "../../styles/_common.css";




.product-card {
  box-shadow: none !important;
  border-style: none !important;

}
.product-card-header {
  font-family: "Roboto" !important;
  font-size:14px !important;

}

.product-card-price {
  font-family: "Roboto" !important;
  font-size:16px !important;
  margin: 0em 0.5em 0em 0em;
}

.product-card-price-slash {
  font-family: "Roboto" !important;
  font-size:14px !important;
  color: #D5D4D2;
  margin: 0em 0.5em 0em 0em;
  text-decoration: line-through;
}

.product-card-button {
  background-color: #DABBBB !important;;
  color: #FFFFFF !important;
  font-weight:600 !important;
  border-radius: 0 !important;
}

.product-card-content {
  min-height: 90px !important;
  box-shadow: none !important;
  border-style: none !important;
  padding: 1em 0em !important;
}

@media screen and (min-width: 768px) {

  .product-card-content {
    min-height: 110px !important;
  }
  .product-card-header {

    font-size:16px !important;

  }
}
