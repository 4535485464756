.carousel {
  margin: 1em 0em;

}

.carousel-container{
  position:relative;
}
.button-back{

  border-style:none;
    background-color:transparent;
    color:#000000;
  position: absolute !important;
  top: 50% !important;
  left: -1% !important;
}

.button-next{

  border-style:none;
  background-color:transparent;
  color:#000000 !important;
  position: absolute;
  top: 50% !important;
  right: -1% !important;

}

.carousel-card-header {
  font-family: "Roboto" !important;
  font-size:14px !important;
}

.carousel-card-price {
  font-family: "Roboto" !important;
  font-size:14px !important;
  margin: 0em 1em 0em 0em;
}

.carousel-card-content {
  min-height: 50px !important;
  box-shadow: none !important;
  border-style: none !important;

}

.carousel-dot{
  background-color: #FFFFFF !important;
}







@media screen and (min-width: 768px) {
  .carousel-card-content {
    min-height: 100px !important;
  }
  .carousel-card-header {

    font-size:12px !important;

  }
  .carousel-card-price {

    font-size:12px !important;

  }
}
