@import "../../styles/_common.css";

.checkout-status-box {
  box-shadow: none !important;
  border: none !important;

  margin:0em 0em 2em 0em !important;

}

.cart-empty-header{
  font-weight: 300 !important;

}

.order-container{
  top: 0px;
  padding: 0em 0em;
  background-color: #FAF6F6;
  /*background-image: url("https://api.coellalove.com/media/30.JPG") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;*/
  min-height: 600px !important;
}


@media screen and (min-width: 768px) {
  .order-container {
    padding: 3em 0em;
    min-height: 800px !important;
  }

}
