@import "../../styles/_common.css";


.button-box {
  min-height: 1em;
  display: block!important;
  text-align: center;
  padding:0;
  position:relative;
  height:100%!important;
  width:100%!important;
  border-style: solid;
  border-color: #D5D4D2;
  border-radius: 0em;
  border-width: 1px;
}

.button-box-text{
  width:100%!important;
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
