@import "../../styles/_common.css";


.order-review {



}

@media screen and (max-width: 768px) {
  .order-review {

    box-shadow: none;
    border-bottom: 1px solid #F1F1F1;

  }
}
