@import "../../styles/_common.css";



.parking-container {
  background-color: #000000;
  position: relative;
  height: 100vh ;
  padding:0;
}

.parking-center{
  margin: 0;
  position: absolute;
  min-width: 80%;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.parking-grid {
  margin:0 !important;
  height:100%
}

.parking-input {


  display:block;
}

.subscribe-header {
  font-size: 18px;
  color:#FFFFFF !important;
  font-family: "Open Sans" !important;
}
