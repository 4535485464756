@import "../../styles/_common.css";

/*FOOTER*/
.footer {
  /*padding: 0 0 4rem 0;*/
  position: fixed !important;

  padding: 80px 0;
  height: 400px;
  color: #000000;
  font-size: 14px;
  left: 0;
  bottom: 0 !important;
  width: 100%,
}

.footer-segment {
  margin: 0em 0em 0em !important;
  padding: 5em 0em !important;
  background-color: #000000 !important;
}
.support-link{
  color:#FFFFFF!important;

}

.footer-list{
  color:#FFFFFF !important;
}
