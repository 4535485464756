@import "../../styles/_common.css";


.coupon-segment {
  background-color: #FFFFFF !important;
  padding:1em 1em !important;


}


@media only screen and (max-width: 768px) {
  .coupon-segment {
      padding:1em 0em !important;
      margin:0em 0em !important;
  }

}
