html{
  height:100%;

  margin: 0;
  padding: 0;
  overflow: auto;

}

body { height: auto; }


#root {
  height:auto;
  margin: 0;
  padding: 0;


}


/*Activate to debug elements
*{ border: 1px solid red; }
*/
