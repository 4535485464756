@import "../../styles/_common.css";



.muuri-item {
  z-index: 0;
  min-height: 280px;
  cursor: pointer;
  position: absolute;
  width: 50%;
  will-change: transform;
  touch-action: pan-y;
}

.muuri-item-content {
  margin: 0.5em;
  justify-content: center;
  align-items: center;
  position: relative;
  touch-action: pan-y;
}



@media screen and (min-width: 768px) {
  .muuri-item {
    width: 33%;
    min-height: 415px;
  }

}
