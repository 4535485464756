@import "../../styles/_common.css";



.main {
  min-width: 100px;
   padding: 0 0px;
   margin: 0 auto;
   height: 260px;
}

.transitionsItem {
  overflow: hidden;
  width: 100%;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Playfair Display" !important;
  font-size: 80px;
  font-weight: 900;
  position: relative;
  will-change: transform, opacity, height;
  white-space: nowrap;
  cursor: pointer;
  line-height: 80px;

}

.header-container {
  display: block;

  height: 100%;


}
