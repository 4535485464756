@import "../../styles/_common.css";

.summary-trailsText {
  position: relative;
  width: 100%;
  height: 80px;
  line-height: 80px;
  color: black;
  font-size: 6em;
  font-weight: 800;
  letter-spacing: -0.05em;
  will-change: transform, opacity;
  overflow: hidden;
}

.checkout-button{
  border-radius: 0 !important;
}
.summary-trailsText > div {
  padding-right: 0.05em;
  overflow: hidden;
}

.item-segment {
  border-radius: 10px !important;
  margin:1em 0em 1em 0em!important;
  position: relative!important;
  padding:1em 0em !important;
  width:100% !important;
}

.trail-item {

  margin:1em 0em;
}
